<template>
    <v-card>
        <v-card-title class="py-1">
            {{ $t('basket-confirmPurchase') }}
            <v-spacer />
            <v-icon
                v-if="activeOrderId === null"
                aria-label="Close"
                size="25"
                @click="$emit('close')"
            >
                mdi-close
            </v-icon>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col
                        cols="7"
                    >
                        <b>{{ $t('product') }}</b>
                    </v-col>
                    <v-col
                        cols="2"
                        class="text-center"
                    >
                        <b>{{ $t('amount') }}</b>
                    </v-col>
                    <v-col
                        cols="3"
                        class="text-right"
                    >
                        <b>{{ $t('settings/productadministration-createButton-priceInclVat') }}</b>
                    </v-col>
                </v-row>
            </v-container>

            <order-item
                v-for="(item, index) in orderItems"
                :key="item.id"
                :item="item"
                :pre-selected-relation-id="index === 0 ? relationId : null"
                :registration-relations-list="registrationRelations"
                :registration-relations-used-id-list="registrationRelationsUsedIds"
                @orderitemchanged="orderItemChanged"
                @valid="orderItemValid"
            /> 
        </v-card-text>
        <v-card-text
            style="text-align: right; font-weight: bold; font-size: large;"
        >
            {{ $t('subtotal') }} {{ globalConvertMinorCurrencyToLocal(orderTotal * 0.8) }}
        </v-card-text>
        <v-card-text
            style="text-align: right; font-weight: bold; font-size: large"
        >
            {{ $t('ordre-total') }} {{ globalConvertMinorCurrencyToLocal(orderTotal) }}
        </v-card-text>

        <v-card-actions>
            <v-btn
                v-if="activeOrderId != null"
                color="red"
                style="color: white;"
                @click="cancelOrder()"
            >
                {{ $t('basket-cancelPurchase') }}
            </v-btn>
            <v-spacer />
            <v-btn
                v-if="freepayPaymentLink === '' && !choosePaymentMethod"
                :disabled="invalidBasket || working"
                color="primary"
                @click="payOrder()"
            >
                {{ $t('basket-buttonConfirm') }}
            </v-btn>
            <v-btn
                v-if="freepayPaymentLink != '' && !choosePaymentMethod"
                color="primary"
                :disabled="invalidBasket || working"
                :href="freepayPaymentLink"
            >
                {{ $t('basket-buttonConfirm') }}
            </v-btn>
            <v-btn
                v-if="(choosePaymentMethod && requireEanToBuySubscription && hasEanNumber) || (choosePaymentMethod && !requireEanToBuySubscription)"
                color="primary"
                :disabled="invalidBasket || working"
                @click="paymentMethod = 0; payOrder()"
            >
                {{ $t('shop-payment-type-0-text') }}
            </v-btn>
            <v-btn
                v-if="choosePaymentMethod"
                color="primary"
                :disabled="invalidBasket || working"
                @click="paymentMethod = 1; payOrder()"
            >
                {{ $t('shop-payment-type-1-text') }}
            </v-btn>            
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapGetters, mapActions, mapMutations } from 'vuex'

export default{
    components:{
        OrderItem : () => import('@/views/components/OrderItemBase.vue')
    },
    props: {
        relationId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            selectedProducts: [],
            loading: true,
            working: false,

            payOrderDialog: false,
            freepayPaymentLink: '',

            orderItems: [],
            registrationRelations: [],
            registrationRelationsUsedIds: [],

            orderTotal: 0,

            allOrderItemsValid: [],

            activeOrderId: null,

            choosePaymentMethod : false,
            paymentMethod: null,

            requireEanToBuySubscription: false,
            hasEanNumber: false
        }
    },
    computed:{
        invalidBasket(){
            return this.allOrderItemsValid.some(x => !x.status)
        },
        ...mapGetters({
            getSelectedProductIdsAndAmount : 'getSelectedProductIdsAndAmount',
            getSelectedPNumber: 'getSelectedPNumber',
            getSelectedCvrNumber: 'getSelectedCvrNumber'
        })
    },
    created() {
        this.loading = true
        Promise.all([
            this.FetchSubscriptionProducts({pNumber: this.getSelectedPNumber}),
            this.FetchCustomerRegistrationRelationList({pNumber: this.getSelectedPNumber}),
            this.GetSystemSetting({type: 5}),
            this.FetchUser({pNumber: this.getSelectedPNumber})
        ])
            .then(results => {
                const arr = Array.from(this.getSelectedProductIdsAndAmount)
           
                this.registrationRelations = results[1].customerRegistrationRelationList
                let customId = 1
                arr.forEach(x => {
                    let product = results[0].products.find(y => y.id === x.id)
                    this.selectedProducts.push(product)
                    this.orderTotal = this.orderTotal + ((product.priceIncludingVatInMinorCurrency) * x.amount)
                    
                    if(product.maxAllowedEntrances === null){
                        let regsWithProduct = this.registrationRelations.filter(y => y.activeSubscription !== null && y.activeSubscription.productId === x.id)

                        let regsWithProductUpcoming = this.registrationRelations.filter(y => y.subcriptionList !== null && y.subcriptionList !== undefined && y.subcriptionList.find(s => s.productId === x.id))

                        if(regsWithProductUpcoming > 0)
                            regsWithProduct.push(regsWithProductUpcoming)                     
                        
                        regsWithProduct.forEach(r => {
                            this.registrationRelationsUsedIds.push({
                                id: r.id,
                                productid: x.id,
                                relationid: r.id
                            })
                        })
                    }
                    
                    /*SUBSCRIPTION PRODUCT REQUIRES THE AMOUNT TO HAVE SEPERATE ORDER LINES*/
                    /*MIGHT ALSO BE NEEDED FOR ENTRANCE PRODUCTS*/
                    if(product.type === '2'){
                        for(let i = 0; i < x.amount; i++){
                            let subscriptionItemWithRegistrationNumber = {
                                id: customId,
                                product: product,
                                amount: 1,
                                registrationNumberRelationId: null
                            }

                            this.orderItems.push(subscriptionItemWithRegistrationNumber)

                            customId = customId+1
                        } 
                    }
                    else {
                        let subscriptionItemWithRegistrationNumber = {
                            id: customId,
                            product: product,
                            amount: x.amount,
                            registrationNumberRelationId: null
                        }

                        this.orderItems.push(subscriptionItemWithRegistrationNumber)

                        customId = customId+1
                    } 
                                  
                })

                this.choosePaymentMethod = results[2].setting ? results[2].setting.choosePaymentMethod : false

                this.requireEanToBuySubscription = results[2].setting ? results[2].setting.requireEanToBuySubscription : false

                this.hasEanNumber = results[3].customer.eanNumber !== null && results[3].customer.eanNumber !== undefined && results[3].customer.eanNumber !== ''

                this.loading = false
            })
    },
    methods: {
        orderItemChanged(data){

            this.registrationRelationsUsedIds = this.registrationRelationsUsedIds.filter(x => x.id !== data.orderitemid)

            this.registrationRelationsUsedIds.push({
                id: data.orderitemid,
                productid: data.productid,
                relationid: data.registrationrelationid
            })
        },
        orderItemValid(data){
            let index = this.allOrderItemsValid.findIndex(item => item.orderItemId == data.orderItemId)
            if(index === -1){
                this.allOrderItemsValid.push(data)
            }
            else {
                this.allOrderItemsValid[index].status = data.status
            }
        },
        payOrder(){
            this.working = true
            this.CreateSubscriptionOrder({pNumber: this.getSelectedPNumber, productIdAndRegistrationRelationIdList: this.registrationRelationsUsedIds, paymentMethod: this.paymentMethod})
                .then(result => {
                    if(result.isFreeOrder){
                        this.$emit('closesuccess')
                    }
                    this.freepayPaymentLink = result.freepayPaymentLink

                    this.setActiveOrderId(result.orderId)
                    this.activeOrderId = result.orderId
                }).finally(() => {
                    if(this.freepayPaymentLink){
                        window.location = this.freepayPaymentLink
                    }
                })
            
            setTimeout(() => {
                this.working = false
            }, 3000)
        },
        cancelOrder(){
            this.working = true
            this.CancelSubscriptionOrder({pNumber: this.getSelectedPNumber, orderId: this.activeOrderId})
                .then(() => {
                    this.$emit('close')
                })
                .finally(() => {
                    this.working = false
                })
        },
        ...mapActions({
            FetchSubscriptionProducts: 'FetchSubscriptionProducts',
            CreateSubscriptionOrder: 'CreateSubscriptionOrder',
            FetchCustomerRegistrationRelationList: 'FetchCustomerRegistrationRelationList',
            CancelSubscriptionOrder: 'CancelSubscriptionOrder',
            GetSystemSetting: 'GetSystemSetting',
            FetchUser: 'FetchUser'
        }),
        ...mapMutations({
            setActiveOrderId: 'setActiveOrderId'
        })
    }
}
</script>